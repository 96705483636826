import React from "react";
import { Header } from "./components/header";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Layout from "./components/Layout";
import { Menu } from "./components/menu";
import { Gallery } from "./components/gallery";
import { Contact } from "./components/contact";
import { Privacy } from "./components/privacy";
import { Rooms } from "./components/rooms";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Header />} />
          <Route path="home" element={<Header />} />
          <Route path="menu" element={<Menu />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="contact" element={<Contact />} />
          <Route path="policy" element={<Privacy />}/>
          <Route path="rooms" element={<Rooms />}/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
