import React, { useEffect, useState } from "react";
import JsonData from "../data/data.json";

export const About = (props) => {

  const [about, setAbout] = useState({})

  useEffect(() => {
    setAbout(JsonData.About);
  }, []);
  return (
    <>
      <div id="about" className="text-center pt-100 pb-95">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-container">
                <h3>Welcome to <span>Royal Skyla Hotel <br /> the heaven </span> of your Weekend</h3>
                <p>
                  Royal Skyla Hotel is a centrally air conditioned & centrally located hotel.
                  It provides smart hospitality solution for corporate and business executives.
                  The hotel is a class apart in several ways. With a focused approach towards serving the traveling corporate,
                  the hotel provides a staying experience that is of high standards and at surprisingly affordable prices with a total of 32 keys.
                  The Wi-Fi enabled hotel offers five different options, based on customer's requirements and budget.
                  Royal Skyla Hotel is all set to turn out as a preferred destination for corporate, business travelers and tourists alike.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="welcome-image-area">
                <div className="first-welcome-image">
                  <img src={about.img1} alt="..." />
                </div>
                <div className="secound-iamge-area">
                  <img src={about.img2} alt="..." />
                  <div className="welcome-title">
                    <h3>Royal Skyla Hotel</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};
