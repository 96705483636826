import React, { useEffect, useState } from "react";
import { About } from "./about";
import { Contact } from "./contact";
import { Reservations } from "./reservations";
import JsonData from "../data/data.json";

export const Header = (props) => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData.Header);
  },[]);
  return (
    <>
      <header id="header">
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intro-text">
                  <h1>
                    {landingPageData ? landingPageData.title : "Loading"}
                    <span></span>
                  </h1>
                  <p>{landingPageData ? landingPageData.subTitle : "Loading"}</p>
                  {/* <a href="#about" className="btn btn-custom btn-lg page-scroll" >
                    Learn More
                  </a>{" "} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <Reservations data={landingPageData.Rooms} /> */}
      <About />
      {/* <Contact data={landingPageData.Contact} /> */}
    </>
  );
};
