import { Navigation } from "./navigation";
import { Outlet } from "react-router-dom";
import JsonData from "../data/data.json"
import { useEffect } from "react";
import { useState } from "react";

const Layout = () => {
    const [contactInfo, setContactInfo] = useState({});
    const [logo, setLogo] = useState({});
    useEffect(()=> {
        setLogo(JsonData.logo.img);
        setContactInfo(JsonData.Contact)
    },[])
    return (
        <>
            <div className="wrap">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col d-flex align-item-center">
                            <p className="mb-0 phone">
                                <span className="mailus"><i className="fa fa-phone" style={{ color: '#ffffff' }} ></i></span>
                                <a href="#" style={{ color: '#ffffff'}} className="phone">
                                    {contactInfo.phone}
                                </a>
                                
                                <span className="mailus"><i className="fa fa-envelope-o" style={{ color: '#ffffff' }} ></i></span>
                                <a href="#" style={{ color: '#ffffff' }} className="email">
                                {contactInfo.email}
                                </a>
                            </p>
                        </div>
                        <div className="col d-flex justify-content-end">

                        </div>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <Navigation />
                <Outlet />
            </div>
            <footer>
		    <div className="footer-container">
		        {/* <!--Footer Top Area Start--> */}
		        <div className="footer-top-area black-bg pt-90 pb-50">
		            <div className="container">
		                <div className="row">
		                    <div className="col-lg-3 col-md-6 col-12">
		                        {/* <!--Single Footer Widget Start--> */}
		                        <div className="single-footer-widget mb-35">
		                            <div className="footer-title">
		                                <h3>Connect With Us</h3>
		                            </div>
		                        
		                            <div className="contact-info">
		                                <ul>
		                                    <li><i className="fa fa-home"></i>{contactInfo.address} </li>
		                                    <li><i className="fa fa-phone"></i> <a href="#"> {contactInfo.phone}</a></li>
		                                    <li><i className="fa fa-envelope-o"></i> <a href="#">{contactInfo.email}</a></li>
		                                </ul>
		                            </div>
		                        </div>
		                        {/* <!--Single Footer Widget End--> */}
		                    </div>
		                    <div className="col-lg-3 col-md-6 col-12">
		                        {/* <!--Single Footer Widget Start--> */}
		                        
		                        {/* <!--Single Footer Widget End--> */}
		                    </div>
		                    <div className="col-lg-3 col-md-6 col-12">
		                        {/* <!--Single Footer Widget Start--> */}
		                        <div className="single-footer-widget mb-35">
		                            <div className="footer-title">
		                                <h3>Useful Links</h3>
		                            </div>
		                            <ul className="link-widget">
		                                {/* <li><a href="/about">About Us</a></li> */}
		                                <li><a href="/rooms">Our Rooms</a></li>
		                                <li><a href="/gallery">Gallery</a></li>
		                               	<li><a href="/menu">Restaurant </a></li>
		                                <li><a href="/contact">Contact</a></li>
										<li><a href="/policy">Policy</a></li>
		                            </ul>
		                        </div>
		                        {/* <!--Single Footer Widget End--> */}
		                    </div>
		                </div>
		            </div>
		        </div> 
		        <div className="footer-bottom-area">
		            <div className="container">
		                <div className="row">
		                    <div className="col-md-6">
		                        <div className="footer-copyright">
		                            <p>Copyright © <a href="#">Royal Skyla Hotel</a> All Rights Reserved</p>
		                        </div>
		                    </div>
		                </div>
		            </div>
		        </div> 
		    </div>
		</footer>
        </>
    )
}

export default Layout;