import React from "react"
import { useEffect } from "react";
import { useState } from "react";
import JsonData from "../data/data.json";


export const Privacy = () => {
    const [docs, setDocs] = useState({});
    useEffect(() => {
        setDocs(JsonData.Docs.policy);
    }, [])
    return(
        <div className="container" >
            {docs ? <embed src={docs} width="800px" height="900px"/> : 'Loading...'}
        </div>
    )
}