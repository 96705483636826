import React, { useEffect, useState } from "react"
import JsonData from "../data/data.json";

export const Menu = () => {
    const [menuData, setMenuData] = useState([]);
    const [other, setOther] = useState({});
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [instructions, setInstructions] = useState("");
    const [mesasge, setMessage] = useState("");
    useEffect(() => {
        resetForm();
        setMessage("");
        setMenuData(JsonData.FoodMenu);
        setOther(JsonData.InfoImages);
    }, [])

    const handleSubmit = function(e) {
        e.preventDefault();
        resetForm();
        setMessage("A table has booked for you.");
    }

    const handleName = function(e) {
        setName(e.target.value);
    }

    const handlePhone = function(e) {
        setPhone(e.target.value);
    }

    const handleInstructions = function(e) {
        setInstructions(e.target.value);
    }

    const resetForm = function() {
        setPhone("");
        setName("");
        setInstructions("");
    }

    return (
        <>
            <header id="header">
                <div className="menu-intro">
                    <div className="overlay">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2 intro-text">
                                    <h2 style={{ color: "white" }}>
                                        Main Menu
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="about-area pt-100 pb-95" id="about">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="about-container">
                            <h3>Multi Cuision Restaurant</h3>
                            <p>It offers delicacies in Indian, Thai, Chinese and Continental Cuisine.It has a very pleasing interior with a high atrium that gives good daylight and transforms a different mood at night</p>
                                            
                            <div className="contact-form">
                               
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="single-input">
                                                <input name="name" placeholder="Name" type="text" value={name} required="" onChange={handleName} />
                                            </div>
                                        </div>
                                     
                                        <div className="col-md-6">
                                            <div className="single-input">
                                                <input name="phone" placeholder="Phone" id="phone" minlength="10" value={phone} onChange={handlePhone} maxlength="10" type="tel" required="" />
                                            </div>
                                        </div>
                                        {/* <!--  <div className="col-md-6">-->
                                        <!--    <div className="single-input">-->
                                        <!--        <input name="item" placeholder="item" type="text" required>-->
                                        <!--    </div>-->
                                        <!--</div>-->
                                        <!--<div className="col-md-6">-->
                                        <!--    <div className="single-input">-->
                                        <!--        <input name="quantity" placeholder="quantity"  type="number" >-->
                                        <!--    </div>-->
                                        <!--</div>--> */}
                                        <div className="col-md-12">
                                            <div className="single-input">
                                                <textarea name="instructions" cols="10" rows="2" placeholder="instructions" value={instructions} onChange={handleInstructions} required=""></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="single-input">
                                                <button className="sent-btn" type="submit" value="submit" name="submit">Book A Table</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <p className="form-messege">{mesasge}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="welcome-image-area">
                            <div className="first-welcome-image">
                                <img src={other.about} alt="" />
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br/>
        <div className="container">
            <div className="row">
                <div className="col-12">
                    {/* <!--Section Title Start--> */}
                    <div className="section-title text-center">
                        <span>We Are ROYAL SKYLA HOTEL</span>
                        <h3>Our Menu</h3>
                    </div>
                    {/* <!--Section Title End--> */}
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <figure >
                        <img src={other.about1} alt="portfolio images" style={{maxWidth: "inherit", width: "100%"}} />
                    </figure>
                </div>
            </div>
        </div>
        <div id="food-menu" className="text-center">
            <div className="container">
                {menuData ? menuData.map((menu, i) =>
                (<>
                <div className={menu.class}>
                    <div className="overlay">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2 intro-text" id="menu-top">
                                    <h2 style={{ color: "white" }}>
                                        {menu.title}
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row menu-item-main-container" key={`${menu.title}-${i}`}>
                    <div className="menu-item-container">
                        {menu.items.map((item, keyId) => (<div className="d-flex flex-wrap flex-row">
                            <div className="col-xs-12 col-md-4 menu-item-box" key={`${item.name}-${keyId}}`}>
                                <h5 className="food-menu-item">
                                    {item.name}
                                </h5>
                                <div className="item-divider"></div>
                            </div>
                        </div>))}
                    </div>
                </div></>)
                ) : "Loading Items..."}

            </div>
        </div>
        </>
    )
}