import { Image } from "./image";
import React, { useEffect, useState } from "react";
import JsonData from "../data/data.json";

export const Gallery = (props) => {
  const [galleryData, setGalleryData] = useState([]);
  useEffect(() => {
    setGalleryData(JsonData.Gallery);
  }, []);

  const renderImageContent = function(src, index) {
    return (
      <div onClick={(e) => this.openModal(e, index)}>
        <img src={src.smallImage} key={src.smallImage} />
      </div>
    ) 
  }

  return (
    <>
      <div className="breadcrumb-banner-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="breadcrumb-text">
                <h1 className="text-center">Gallery</h1>
                <div className="breadcrumb-bar">
                  <ul className="breadcrumb text-center">
                    <li><a href="/home">Home</a></li>
                    <li>Gallery</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
    </div>
      <div id="portfolio" className="text-center">
        <div className="container">
          <div className="row">
            <div className="portfolio-items">
              {galleryData
                ? galleryData.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
                : "Loading..."}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
