import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import JsonData from "../data/data.json";

export const Navigation = () => {
  const [activeTab, setActiveTab] = useState('Home');
  const [top, setTop] = useState('navbar-fixed-top-auto');
  const [logo, setLogo] = useState({});

  useEffect(() => {
    setLogo(JsonData.logo);
    const onScroll = () => setTop(window.scrollY === 0 ? 'navbar-fixed-top-auto': '');
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
  }, [])
  
  function handleActive(e) {
    setActiveTab(e.target.text);
  }
  return (
    <nav id="menu" className={["navbar navbar-default navbar-fixed-top", top].join(" ")}>
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <Link className="navbar-brand page-scroll" to="/home" onClick={handleActive}>
              <img src={logo.img} height="50px" width="50px" style={{verticalAlign: "bottom", display: "inline-block", marginRight: "5px" }}/>
              Royal Skyla Hotel
          </Link>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li className={activeTab === 'Home' ? 'active' : ''}>
              <Link to="/home" className="page-scroll" onClick={handleActive}>
                Home
              </Link>
            </li>
            <li className={activeTab === 'Rooms' ? 'active' : ''}>
              <Link to="/rooms" className="page-scroll" onClick={handleActive}>
                Rooms
              </Link>
            </li>
            <li className={activeTab === 'Restaurant' ? 'active' : ''}>
              <Link to="/menu" className="page-scroll" onClick={handleActive}>
                Restaurant
              </Link>
            </li>
            <li className={activeTab === 'Gallery' ? 'active' : ''}>
              <Link to="/gallery" className="page-scroll" onClick={handleActive}>
                Gallery
              </Link>
            </li>
            <li className={activeTab === 'Contact' ? 'active' : ''}>
              <a href="/contact" className="page-scroll" onClick={handleActive}>
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
