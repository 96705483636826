import { useEffect, useState } from "react";
import JsonData from "../data/data.json";

export const Rooms = () => {
    const [roomImages, setRoomImages] = useState({});
    useEffect(() => {
        setRoomImages(JsonData.Rooms);
    },[])

    return (
        <>
            <div className="breadcrumb-banner-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb-text">
                                <h1 className="text-center">Room List</h1>
                                <div className="breadcrumb-bar">
                                    <ul className="breadcrumb text-center">
                                        <li><a href="/home">Home</a></li>
                                        <li>Room List</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="room-list-area pt-95 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title title-2 text-center mb-50">
                                <h3>our favorite rooms</h3>
                                <p>Royal Skyla Hotel , a centrally air conditioned &amp; centrally located hotel, provides smart hospitality solution for corporate and business executives.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="room-list-item">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="room-img img-full">
                                            <img src={roomImages.StandardRoomSingle} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="room-list-content">
                                            <h3>Standard Room | Single</h3>
                                            <p>24 hours check out time
                                                Tariff includes complimentary buffet breakfast at the coffee shop </p>
                                            <h4>Room Facility</h4>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <ul>
                                                        <li>
                                                            <p><i className="fa fa-bed"></i> Bed <span> : 1 King Bed</span></p>
                                                        </li>
                                                        {/* <li>
                                                            <p><i className="fa fa-snowflake-o" aria-hidden="true"></i> Room AC <span> : Yes</span></p>
                                                        </li> */}
                                                        <li>
                                                            <p><i className="fa fa-wifi"></i> Free Wifi <span> : Yes</span></p>
                                                        </li>
                                                        <li>
                                                            <p><i className="fa fa-coffee"></i> Tea/Coffee maker <span> : Yes</span></p>
                                                        </li>

                                                    </ul>
                                                </div>
                                                <div className="col-md-6">
                                                    <ul>
                                                        <li>
                                                            <p><i className="fa fa-glass"></i> MiniBar<span> : Yes</span></p>
                                                        </li>
                                                        <li>
                                                            <p><i className="fa fa-lock"></i> Electronic safety locker<span> : Yes</span></p>
                                                        </li>
                                                        <li>
                                                            <p><i className="fa fa-check "></i>Contact less CheckIn &amp; CheckOut<span> : Yes</span></p>
                                                        </li>
                                                        <li>
                                                            <p><i className="fa fa-users"></i> Max Occupancy <span>: 2 Adult, 1 Child</span></p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="room-book-action">
                                                <div className="room-price">
                                                    <ul>
                                                        <li>
                                                            <h6>Starting Form : </h6>
                                                        </li>
                                                        <li>
                                                            <p><span>₹</span>3899.00<sup>*</sup> /<span>Day</span></p>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="book-room">
                                                    <a href="/contact"> Contact Us for booking <i className="fa fa-caret-right" aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room-list-item">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="room-img img-full">
                                            <img src={roomImages.StandardRoomDouble} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="room-list-content">
                                            <h3>Standard Room | Double</h3>
                                            <p>24 hours check out time
                                                Tariff includes complimentary buffet breakfast at the coffee shop </p>
                                            <h4>Room Facility</h4>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <ul>
                                                        <li>
                                                            <p><i className="fa fa-bed"></i> Bed <span> : 1 King Bed</span></p>
                                                        </li>
                                                        {/* <li>
                                                            <p><i className="fa fa-snowflake-o" aria-hidden="true"></i> Room AC <span> : Yes</span></p>
                                                        </li> */}
                                                        <li>
                                                            <p><i className="fa fa-wifi"></i> Free Wifi <span> : Yes</span></p>
                                                        </li>
                                                        <li>
                                                            <p><i className="fa fa-coffee"></i> Tea/Coffee maker <span> : Yes</span></p>
                                                        </li>

                                                    </ul>
                                                </div>
                                                <div className="col-md-6">
                                                    <ul>
                                                        <li>
                                                            <p><i className="fa fa-glass"></i> MiniBar<span> : Yes</span></p>
                                                        </li>
                                                        <li>
                                                            <p><i className="fa fa-lock"></i> Electronic safety locker<span> : Yes</span></p>
                                                        </li>
                                                        <li>
                                                            <p><i className="fa fa-check "></i>Contact less CheckIn &amp; CheckOut<span> : Yes</span></p>
                                                        </li>
                                                        <li>
                                                            <p><i className="fa fa-users"></i> Max Occupancy <span>: 2 Adult, 1 Child</span></p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="room-book-action">
                                                <div className="room-price">
                                                    <ul>
                                                        <li>
                                                            <h6>Starting Form : </h6>
                                                        </li>
                                                        <li>
                                                            <p><span>₹</span>4299.00<sup>*</sup> /<span>Day</span></p>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="book-room">
                                                    <a href="/contact"> Contact Us for booking <i className="fa fa-caret-right" aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room-list-item">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="room-img img-full">
                                            <img src={roomImages.ExecutiveRoomSingle} alt=""/>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="room-list-content">
                                            <h3>Executive Room | Single</h3>
                                            <p>24 hours check out time
                                                Tariff includes complimentary buffet breakfast at the coffee shop </p>
                                            <h4>Room Facility</h4>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <ul>
                                                        <li>
                                                            <p><i className="fa fa-bed"></i> Bed <span> : 1 King Bed</span></p>
                                                        </li>
                                                        {/* <li>
                                                            <p><i className="fa fa-snowflake-o" aria-hidden="true"></i> Room AC <span> : Yes</span></p>
                                                        </li> */}
                                                        <li>
                                                            <p><i className="fa fa-wifi"></i> Free Wifi <span> : Yes</span></p>
                                                        </li>
                                                        <li>
                                                            <p><i className="fa fa-coffee"></i> Tea/Coffee maker <span> : Yes</span></p>
                                                        </li>

                                                    </ul>
                                                </div>
                                                <div className="col-md-6">
                                                    <ul>
                                                        <li>
                                                            <p><i className="fa fa-glass"></i> MiniBar<span> : Yes</span></p>
                                                        </li>
                                                        <li>
                                                            <p><i className="fa fa-lock"></i> Electronic safety locker<span> : Yes</span></p>
                                                        </li>
                                                        <li>
                                                            <p><i className="fa fa-check "></i>Contact less CheckIn &amp; CheckOut<span> : Yes</span></p>
                                                        </li>
                                                        <li>
                                                            <p><i className="fa fa-users"></i> Max Occupancy <span>: 2 Adult, 1 Child</span></p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="room-book-action">
                                                <div className="room-price">
                                                    <ul>
                                                        <li>
                                                            <h6>Starting Form : </h6>
                                                        </li>
                                                        <li>
                                                            <p><span>₹</span>4499.00<sup>*</sup> /<span>Day</span></p>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="book-room">
                                                    <a href="/contact"> Contact Us for booking <i className="fa fa-caret-right" aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room-list-item">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="room-img img-full">
                                            <img src={roomImages.ExecutiveRoomDouble} alt=""/>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="room-list-content">
                                            <h3>Executive Room | Double</h3>
                                            <p>24 hours check out time
                                                Tariff includes complimentary buffet breakfast at the coffee shop </p>
                                            <h4>Room Facility</h4>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <ul>
                                                        <li>
                                                            <p><i className="fa fa-bed"></i> Bed <span> : 1 King Bed</span></p>
                                                        </li>
                                                        {/* <li>
                                                            <p><i className="fa fa-snowflake-o" aria-hidden="true"></i> Room AC <span> : Yes</span></p>
                                                        </li> */}
                                                        <li>
                                                            <p><i className="fa fa-wifi"></i> Free Wifi <span> : Yes</span></p>
                                                        </li>
                                                        <li>
                                                            <p><i className="fa fa-coffee"></i> Tea/Coffee maker <span> : Yes</span></p>
                                                        </li>

                                                    </ul>
                                                </div>
                                                <div className="col-md-6">
                                                    <ul>
                                                        <li>
                                                            <p><i className="fa fa-glass"></i> MiniBar<span> : Yes</span></p>
                                                        </li>
                                                        <li>
                                                            <p><i className="fa fa-lock"></i> Electronic safety locker<span> : Yes</span></p>
                                                        </li>
                                                        <li>
                                                            <p><i className="fa fa-check "></i>Contact less CheckIn &amp; CheckOut<span> : Yes</span></p>
                                                        </li>
                                                        <li>
                                                            <p><i className="fa fa-users"></i> Max Occupancy <span>: 2 Adult, 1 Child</span></p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="room-book-action">
                                                <div className="room-price">
                                                    <ul>
                                                        <li>
                                                            <h6>Starting Form : </h6>
                                                        </li>
                                                        <li>
                                                            <p><span>₹</span>5299.00<sup>*</sup> /<span>Day</span></p>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="book-room">
                                                    <a href="/contact"> Contact Us for booking <i className="fa fa-caret-right" aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room-list-item">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="room-img img-full">
                                            <img src={roomImages.DeluxeRoomSingle} alt=""/>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="room-list-content">
                                            <h3>Deluxe Room | Single</h3>
                                            <p>24 hours check out time.Tariff includes complimentary buffet breakfast at the cofee shop. No charges for children below 10 years without an extra bed. 24 hrs. Room service.
                                                Finest quality of bed and linen.</p>
                                            <h4>Room Facility</h4>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <ul>
                                                        <li>
                                                            <p><i className="fa fa-bed"></i> Bed <span> : 1 King Bed</span></p>
                                                        </li>
                                                        {/* <li>
                                                            <p><i className="fa fa-snowflake-o" aria-hidden="true"></i> Room AC <span> : Yes</span></p>
                                                        </li> */}
                                                        <li>
                                                            <p><i className="fa fa-wifi"></i> Free Wifi <span> : Yes</span></p>
                                                        </li>
                                                        <li>
                                                            <p><i className="fa fa-coffee"></i> Tea/Coffee maker <span> : Yes</span></p>
                                                        </li>

                                                    </ul>
                                                </div>
                                                <div className="col-md-6">
                                                    <ul>
                                                        <li>
                                                            <p><i className="fa fa-glass"></i> MiniBar<span> : Yes</span></p>
                                                        </li>
                                                        <li>
                                                            <p><i className="fa fa-lock"></i> Electronic safety locker<span> : Yes</span></p>
                                                        </li>
                                                        <li>
                                                            <p><i className="fa fa-check "></i>Contact less CheckIn &amp; CheckOut<span> : Yes</span></p>
                                                        </li>
                                                        <li>
                                                            <p><i className="fa fa-users"></i> Max Occupancy <span>: 2 Adult, 1 Child</span></p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="room-book-action">
                                                <div className="room-price">
                                                    <ul>
                                                        <li>
                                                            <h6>Starting Form : </h6>
                                                        </li>
                                                        <li>
                                                            <p><span>₹</span>5499.00<sup>*</sup> /<span>Day</span></p>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="book-room">
                                                    <a href="/contact"> Contact Us for booking <i className="fa fa-caret-right" aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room-list-item">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="room-img img-full">
                                            <img src={roomImages.DeluxeRoomDouble} alt=""/>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="room-list-content">
                                            <h3>Deluxe Room | Double</h3>
                                            <p>24 hours check out time.Tariff includes complimentary buffet breakfast at the cofee shop. No charges for children below 10 years without an extra bed. 24 hrs. Room service.
                                                Finest quality of bed and linen.</p>
                                            <h4>Room Facility</h4>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <ul>
                                                        <li>
                                                            <p><i className="fa fa-bed"></i> Bed <span> : 1 King Bed</span></p>
                                                        </li>
                                                        {/* <li>
                                                            <p><i className="fa fa-snowflake-o" aria-hidden="true"></i> Room AC <span> : Yes</span></p>
                                                        </li> */}
                                                        <li>
                                                            <p><i className="fa fa-wifi"></i> Free Wifi <span> : Yes</span></p>
                                                        </li>
                                                        <li>
                                                            <p><i className="fa fa-coffee"></i> Tea/Coffee maker <span> : Yes</span></p>
                                                        </li>

                                                    </ul>
                                                </div>
                                                <div className="col-md-6">
                                                    <ul>
                                                        <li>
                                                            <p><i className="fa fa-glass"></i> MiniBar<span> : Yes</span></p>
                                                        </li>
                                                        <li>
                                                            <p><i className="fa fa-lock"></i> Electronic safety locker<span> : Yes</span></p>
                                                        </li>
                                                        <li>
                                                            <p><i className="fa fa-check "></i>Contact less CheckIn &amp; CheckOut<span> : Yes</span></p>
                                                        </li>
                                                        <li>
                                                            <p><i className="fa fa-users"></i> Max Occupancy <span>: 2 Adult, 1 Child</span></p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="room-book-action">
                                                <div className="room-price">
                                                    <ul>
                                                        <li>
                                                            <h6>Starting Form : </h6>
                                                        </li>
                                                        <li>
                                                            <p><span>₹</span>6299.00<sup>*</sup> /<span>Day</span></p>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="book-room">
                                                    <a href="/contact"> Contact Us for booking <i className="fa fa-caret-right" aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}